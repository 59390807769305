import { Link } from 'gatsby';
import React from 'react';
import Container from '../components/Container';
import RoundImageText from '../components/RoundImageText';
import SEO from '../components/SEO';

export default function Index() {
    return (
        <Container>
            <SEO title='' />
            <div className='text-center'>
                <h1 className='text-center leading-tight mb-14'>
                    404 <br /> Diese Seite konnte leider nicht gefunden werden
                </h1>
                <Link className='btn text-center' to='/'>
                    Zur Startseite
                </Link>
            </div>
        </Container>
    );
}
